<template>
    <div class="d-flex gradiant-bg2 rounded-3 align-items-center justify-content-between flex-wrap p-3">
        <div class="top_bar">
            <h6 class="heading">{{ data.heading }}</h6>
            <p class="subtitle">{{ data.subheading }}</p>
        </div>
        <div class="" v-if="data.buttons">
            <div class="d-flex justify-content-lg-end gap-2">
              <router-link :to="item.link"
                class="btn rounded-3 py-1 top-btns text-secondary bg-white" v-for="(item, index) of data.buttons" :key="index">{{ item.name }}</router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    name:'TopHeading',
    props:{
        data:Object,
    },
}
</script>
<style scoped>
.page-title .heading {
  margin-bottom: 0 !important;
  font-size: 24px !important;
}
.top-btns{
    font-weight: 500;
}
@media only screen and (max-width: 767px) {
    .top-btns{
        margin-top: .5rem;
        font-weight: 500;
        /* border: 2px solid white; */
        /* color: #fff!important; */
    }

}
</style>