<template>
    <div class="card-tm">
        <div class="container container-row py-4">
            <div class="row gy-3">
                <div class="col-12">
                    <TopHeading :data="topHeading" />
                </div>
                <div class="col-12">
                    <div class="row gy-3">
                        <!-- ONGOING -->
                        <div class="col-12" v-if="response.ongoing">
                            <div class="row gy-3">
                                <div class="col-12">
                                    <h6 class="title fw-600 fs-18 m-0">Crowdfunding</h6>
                                </div>
                                <div class="col-lg-4 col-12" v-for="(launchpad, index) in response.ongoing" :key="index">
                                    <div class="card rounded-3">
                                        <div class="card-body">
                                            <div class="row gy-3">
                                                <div class="col-12">
                                                    <div class="gap-3 d-flex align-items-center">
                                                        <img :src="launchpad.image" alt="image"
                                                            class="border rounded-circle token_img" width="40">
                                                        <div
                                                            class="d-flex justify-content-between flex-wrap align-items-center w-100">
                                                            <h6 class="fs-5 m-0">{{ launchpad.symbol }}</h6>
                                                            <span class="fs-12 badge success-badge rounded-pill">Ongoing</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row gy-2 fs-14">
                                                        <div
                                                            class="d-flex justify-content-between flex-wrap align-items-center w-100">
                                                            <span>Allocation</span>
                                                            <span class="fw-600">{{launchpad.total_limits}}</span>
                                                        </div>
                                                        <div
                                                            class="d-flex justify-content-between flex-wrap align-items-center w-100">
                                                            <span>Price</span>
                                                            <span class="fw-600">1 {{launchpad.symbol}} = USDT</span>
                                                        </div>
                                                        <div
                                                            class="d-flex justify-content-between flex-wrap align-items-center w-100">
                                                            <span>Close Date</span>
                                                            <span class="fw-600">{{ launchpad.expired_at }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <p class="fs-14 mb-0">{{ (launchpad.disclaimer).slice(0, 70) }}...</p>
                                                </div>
                                                <div class="col-12">
                                                    <router-link :to="{name:'TokenView', params:{slug:launchpad.id}}"
                                                        class="btn border-primary text-primary rounded-3 bg-transparent w-100">Participate</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- UPCOMING -->
                        <div class="col-12" v-if="response.upcoming">
                            <div class="row gy-3">
                                <div class="col-12">
                                    <h6 class="title fw-600 fs-18 m-0">Upcoming</h6>
                                </div>
                                <div class="col-lg-4 col-12" v-for="(launchpad, index) in response.upcoming" :key="index">
                                    <div class="card rounded-3">
                                        <div class="card-body">
                                            <div class="row gy-3">
                                                <div class="col-12">
                                                    <div class="gap-3 d-flex align-items-center">
                                                        <img src="https://mcoinscan.com/asset/images/logo.png" alt="image"
                                                            class="border rounded-circle token_img" width="40">
                                                        <div
                                                            class="d-flex justify-content-between flex-wrap align-items-center w-100">
                                                            <h6 class="fs-5 m-0">{{ launchpad.symbol }}</h6>
                                                            <span class="fs-12 badge warning-badge rounded-pill">upcoming</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row gy-2 fs-14">
                                                        <div
                                                            class="d-flex justify-content-between flex-wrap align-items-center w-100">
                                                            <span>Allocation</span>
                                                            <span class="fw-600">{{launchpad.total_limits}}</span>
                                                        </div>
                                                        <div
                                                            class="d-flex justify-content-between flex-wrap align-items-center w-100">
                                                            <span>Price</span>
                                                            <span class="fw-600">1 {{launchpad.symbol}} = USDT</span>
                                                        </div>
                                                        <div
                                                            class="d-flex justify-content-between flex-wrap align-items-center w-100">
                                                            <span>Close Date</span>
                                                            <span class="fw-600">{{ launchpad.expired_at }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <p class="fs-14 mb-0">{{ (launchpad.disclaimer).slice(0, 70) }}...</p>
                                                </div>
                                                <div class="col-12">
                                                    <router-link :to="{name:'TokenView', query:{token:launchpad.id}}"
                                                        class="btn border-primary text-primary rounded-3 bg-transparent w-100">Participate</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- PAST -->
                        <div class="col-12" v-if="response.past">
                            <div class="row gy-3">
                                <div class="col-12">
                                    <h6 class="title fw-600 fs-18 m-0">Past</h6>
                                </div>
                                <div class="col-lg-4 col-12" v-for="(launchpad, index) in response.past" :key="index">
                                    <div class="card rounded-3">
                                        <div class="card-body">
                                            <div class="row gy-3">
                                                <div class="col-12">
                                                    <div class="gap-3 d-flex align-items-center">
                                                        <img src="https://mcoinscan.com/asset/images/logo.png" alt="image"
                                                            class="border rounded-circle token_img" width="40">
                                                        <div
                                                            class="d-flex justify-content-between flex-wrap align-items-center w-100">
                                                            <h6 class="fs-5 m-0">{{ launchpad.symbol }}</h6>
                                                            <span class="fs-12 badge secondary-badge rounded-pill">past</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row gy-2 fs-14">
                                                        <div
                                                            class="d-flex justify-content-between flex-wrap align-items-center w-100">
                                                            <span>Allocation</span>
                                                            <span class="fw-600">{{launchpad.total_limits}}</span>
                                                        </div>
                                                        <div
                                                            class="d-flex justify-content-between flex-wrap align-items-center w-100">
                                                            <span>Price</span>
                                                            <span class="fw-600">1 {{launchpad.symbol}} = USDT</span>
                                                        </div>
                                                        <div
                                                            class="d-flex justify-content-between flex-wrap align-items-center w-100">
                                                            <span>Close Date</span>
                                                            <span class="fw-600">{{ launchpad.expired_at }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <p class="fs-14 mb-0">{{ (launchpad.disclaimer).slice(0, 70) }}...</p>
                                                </div>
                                                <div class="col-12">
                                                    <router-link :to="{name:'TokenView', query:{token:launchpad.id}}"
                                                        class="btn border-primary text-primary rounded-3 bg-transparent w-100">Participate</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TopHeading from "@/components/ui/TopHeading.vue";
import Apiclass from '@/api/api';
// import OngoingComponent from '@/components/Launchpadnew/OngoingComponent.vue'
export default {
    name: 'Launchpad',
    components: {
        // OngoingComponent
        TopHeading
    },
    data() {
        return {
            topHeading: {
                heading: 'Launchpad',
                subheading: 'Receive rewards for exchanging digital assets',
                buttons: [
                    // { name: 'Spot Wallet', link: '/wallet/spot', class: 'text-primary border-primary' },
                    { name: 'Orders History', link: '/user/launchpad-orders', class: 'btn-primary text-white' }
                ],
            },
            response: {}
        }
    },
    created() {
        this.tokenGet();
    },
    methods: {
        async tokenGet() {
            let res = await Apiclass.getNodeRequest('launchpad/user/token_get')
            this.response = res.data.data;
            console.log({ res: this.response });
        }
    }
}
</script>

<style scoped>
.success-badge {
    color: #14B8A6;
    background-color: #CCFBF1;
    font-weight: 500;
    padding: 0.25rem 0.5rem;
}

.danger-badge {
    color: #EF4444;
    background-color: #FEE2E2;
    font-weight: 500;
    padding: 0.25rem 0.5rem;
}

.warning-badge {
    color: #EF9E00;
    background-color: #ffdfa1;
    font-weight: 500;
    padding: 0.25rem 0.5rem;
}

.token_img {
    padding: .1rem;
}
</style>
